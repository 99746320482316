import { gql } from "graphql-tag";

export const PERMISSION_GROUP_FIELDS = gql`
  fragment PermissionGroupFields on PermissionGroup {
    id
    name
    path
    created_at
  }
`;

export const PAGINATION = gql`
  fragment Pagination on PermissionGroupPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;


export default { PERMISSION_GROUP_FIELDS, PAGINATION }