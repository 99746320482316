import { gql } from "graphql-tag";
import {PAGINATION, PERMISSION_GROUP_FIELDS } from "./Fragments";

export const GET_PERMISSION_GROUP = gql`
    query GetPermissionGroup($page: Int, $limit: Int) {
        permission_groups(limit:$limit, page:$page){
            ...Pagination
            data {
                ...PermissionGroupFields
            }
        }
    }
    ${PERMISSION_GROUP_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_PERMISSION_GROUP = gql`
    query SearchPermissionGroup($page: Int, $limit: Int, $search_key:String, $type:String , $date_from:String , $date_to:String) {
        search_permission_group(limit:$limit, page:$page, search_key:$search_key, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...PermissionGroupFields
            }
        }
    }
    ${PERMISSION_GROUP_FIELDS}
    ${PAGINATION}
`;

export default { GET_PERMISSION_GROUP,SEARCH_PERMISSION_GROUP }