
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent,onMounted} from "vue";
import AddPermissionGroup from '../components/AddPermissionGroup.vue'
import PermissionGroupsList from "../components/PermissionGroupsList.vue";
import EditPermissionGroup from "../components/EditPermissionGroup.vue";
export default defineComponent({
	components:{
      AddPermissionGroup,
      PermissionGroupsList,
      EditPermissionGroup
	},
	name: "PermissionGroup",
	setup() {
        onMounted(() => {
            setPageHeader({
                title: "message.PERMISSION_GROUPS",
                actionButton: {
                    ability:'add_permission_group',
                    pageAction: {
                        action: "addPermissionGroupAction",
                    },
                    button: {
                        title: `message.ADD message.PERMISSION_GROUP`,
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.PERMISSIONS", link: "#"},
                    { name: "message.PERMISSION_GROUPS"}
                ]
            })
        })
	},
});
