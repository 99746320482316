import { gql } from "graphql-tag";
import { PERMISSION_GROUP_FIELDS } from "./Fragments";

export const CREATE_PERMISSION_GROUP = gql`
	mutation CreatePermissionGroup($input: PermissionGroupInput!) {
		create_permission_group(permission_group: $input) {
			...PermissionGroupFields
		}
	}
	${PERMISSION_GROUP_FIELDS}
`;

export const EDIT_PERMISSION_GROUP = gql`
	mutation EditPermissionGroup($input: PermissionGroupInput!) {
		update_permission_group(permission_group: $input) {
			...PermissionGroupFields
		}
	}
	${PERMISSION_GROUP_FIELDS}
`;

export const DELETE_PERMISSION_GROUP = gql`
	mutation DeletePermissionGroup($id: Int) {
		delete_permission_group(id: $id)
	}
`;

export default { CREATE_PERMISSION_GROUP, EDIT_PERMISSION_GROUP, DELETE_PERMISSION_GROUP };
