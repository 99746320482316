
import { defineComponent, ref, onMounted, inject, watchEffect} from "vue";
import { useStore } from "vuex";
import { Apollo, Notify } from "@/core/services";
import { GET_PERMISSION_GROUP, SEARCH_PERMISSION_GROUP } from "../graphql/Queries";
import { DELETE_PERMISSION_GROUP } from "../graphql/Mutations";
import Search from "../../../../components/search/Search.vue";
import { useI18n } from "vue-i18n";
import Table from "../../../../components/Table/Table.vue";
import InnerLoader from "../../../../components/InnerLoader.vue";
import { dropdown_handler } from "../../../../core/helpers/dropdownHandler";

export default defineComponent({
	components: {
		Search,
		Table,
		InnerLoader,
	},
	name: "Permission Group",
	setup() {
		const emitter: any = inject("emitter");
		const store = useStore();
		const i18n = useI18n();
		const permissionGroups = ref([]) as Record<any, any>;
		const pagination = ref({}) as Record<any, any>;
		const clickHandler = ref() as Record<any, any>;
		const currentPage = ref();
		const loader = ref(false);
		const loading = ref(false);
		const systemLocale = ref();
    const searchObservable: any = ref(null);
    const observable: any = ref(null);
    const permissions = store.getters?.getPermissions;
		const activeIndex = ref(-1);

		const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.CREATED_AT",
				key: "created_at",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);
		
		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const getPermissionGroup = (page = 1, reload = false) => {
      if (searchObservable.value != null) {
        searchObservable.value?.unsubscribe();
        searchObservable.value = null;
      }
      if(page == 1) observable.value?.unsubscribe();
      loader.value = true;
      observable.value = Apollo.watchQuery({
				query: GET_PERMISSION_GROUP,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-and-network",
				variables: {
					page: page,
					limit: 10,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				reload ? (currentPage.value = 1) : false;
				clickHandler.value = getPermissionGroup;
				permissionGroups.value = data.permission_groups.data;
				pagination.value = data.permission_groups;
				loader.value = false;
			});
		};

		onMounted(() => {
			getPermissionGroup(1);
			document.addEventListener("click", handleClickOutside);
		});

		const searchHandler = (page = 1) => {
      if(observable.value != null) {
        observable.value?.unsubscribe();
        observable.value = null
      }
      searchObservable.value?.unsubscribe();
      const searchData = store.getters.getSearchData;
			loader.value = true;
      searchObservable.value = Apollo.watchQuery({
        query: SEARCH_PERMISSION_GROUP,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
				variables: {
					page: page,
					limit: 10,
					search_key: searchData.data.input,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}

				clickHandler.value = getPermissionGroup;
				permissionGroups.value = data.search_permission_group.data;
				pagination.value = data.search_permission_group;
				loader.value = false;
			});
		};

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_PERMISSION_GROUP,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					filter: searchData?.data?.select ? searchData?.data?.select : "",
					search_key: searchData?.data?.input ? searchData?.data?.input : "",

					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_permission_group?.data[0]?.path, "_blank");
					loading.value = false;
				}

				loading.value = false;
				sub.unsubscribe();
			});
		};

		const handleCurrentChange = (num: number) => {
			clickHandler.value(num);
		};

		const handleEdit = (permissionGroup) => {
			emitter.emit("editPermissionGroupAction", permissionGroup);
		};

		const handleDelete = (id) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_PERMISSION_GROUP,
						variables: { id: id },
						update: (store, { data: { delete_permission_group } }) => {
              if (searchObservable.value != null) searchHandler(pagination.value.current_page)
              else getPermissionGroup(pagination.value.current_page)
              loader.value = false;
						},
					});
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		return {
			pagination,
			currentPage,
			permissionGroups,
			columns,
			permissions,
			loading,
			loader,
			exportFile,
			getPermissionGroup,
			searchHandler,
			handleCurrentChange,
			handleEdit,
			handleDelete,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
			systemLocale
		};
	},
});
