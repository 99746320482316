import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PermissionGroupsList = _resolveComponent("PermissionGroupsList")!
  const _component_AddPermissionGroup = _resolveComponent("AddPermissionGroup")!
  const _component_EditPermissionGroup = _resolveComponent("EditPermissionGroup")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_PermissionGroupsList),
    _createVNode(_component_AddPermissionGroup),
    _createVNode(_component_EditPermissionGroup)
  ], 64))
}